import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// Helper function to safely parse JSON from localStorage
function safeJSONParse(key, defaultValue = []) {
  try {
    const item = localStorage.getItem(key);
    if (!item) return defaultValue;

    // Handle potential string values
    if (typeof item === 'string' && !item.startsWith('[')) {
      return [item];
    }

    const parsed = JSON.parse(item);
    return Array.isArray(parsed) ? parsed : defaultValue;
  } catch (error) {
    console.error(`Error parsing ${key} from localStorage:`, error);
    return defaultValue;
  }
}

// Helper to get user info from localStorage
function getUserFromStorage() {
  return {
    userName: localStorage.getItem('user') || '',
    userEmail: localStorage.getItem('email') || '',
    userRoles: safeJSONParse('roles', []),
    tenantId: localStorage.getItem('defaultTenantID') || '',
  };
}

// Helper function to check admin permissions
function isAdminRole(role) {
  const adminRoles = ['admin'];
  return adminRoles.some(adminRole => role.toLowerCase().includes(adminRole.toLowerCase()));
}

const useGlobalStore = create(
  devtools(
    set => ({
      user: false,
      setUser: data => set(() => ({ user: data })),

      ...getUserFromStorage(),

      isAdmin: () => {
        const roles = safeJSONParse('roles', []);
        return Array.isArray(roles) && roles.some(role => isAdminRole(role));
      },

      updateUserInfo: ({ name, email, roles }) => {
        if (Array.isArray(roles)) {
          localStorage.setItem('roles', JSON.stringify(roles));
        }
        if (name) localStorage.setItem('user', name);
        if (email) localStorage.setItem('email', email);

        set(state => ({
          userName: name || state.userName,
          userEmail: email || state.userEmail,
          userRoles: Array.isArray(roles) ? roles : state.userRoles,
        }));
      },

      boardEditMode: false,
      setBoardEditMode: data => set(() => ({ boardEditMode: data })),

      currentProjectObj: null,
      setCurrentProjectObj: data => set(() => ({ currentProjectObj: data })),

      currentBoardObj: null,
      setCurrentBoardObj: data => set(() => ({ currentBoardObj: data })),

      tenantId: getUserFromStorage().tenantId,
      setTenantId: id => {
        localStorage.setItem('defaultTenantID', id);
        set(() => ({ tenantId: id }));
      },

      getCurrentProjectId: () => {
        const state = useGlobalStore.getState();
        return state.currentProjectObj?.id;
      },

      getCurrentTenantId: () => {
        const state = useGlobalStore.getState();
        return state.tenantId;
      },
    }),
    { name: 'dashboard-global-store' }
  )
);

export default useGlobalStore;
